@font-face {
  font-family: Domine;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Domine Regular ), local(Domine-Regular), url("domine-latin-400.5069aa33.woff2") format("woff2"), url("domine-latin-400.f86718ef.woff") format("woff");
}

@font-face {
  font-family: Domine;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Domine Medium ), local(Domine-Medium), url("domine-latin-500.e2bac074.woff2") format("woff2"), url("domine-latin-500.37ba6cc7.woff") format("woff");
}

@font-face {
  font-family: Domine;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(Domine SemiBold ), local(Domine-SemiBold), url("domine-latin-600.58362d31.woff2") format("woff2"), url("domine-latin-600.bbd8ff52.woff") format("woff");
}

@font-face {
  font-family: Domine;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Domine Bold ), local(Domine-Bold), url("domine-latin-700.26341d60.woff2") format("woff2"), url("domine-latin-700.1cfbd676.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(Lato Thin ), local(Lato-Thin), url("lato-latin-100.545af5b3.woff2") format("woff2"), url("lato-latin-100.45d60cf2.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local(Lato Thin italic), local(Lato-Thinitalic), url("lato-latin-100italic.caadc4a1.woff2") format("woff2"), url("lato-latin-100italic.8f7a392e.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Lato Light ), local(Lato-Light), url("lato-latin-300.40bdb81f.woff2") format("woff2"), url("lato-latin-300.b163a960.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Lato Light italic), local(Lato-Lightitalic), url("lato-latin-300italic.7abf5ae8.woff2") format("woff2"), url("lato-latin-300italic.62e4c8b4.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Lato Regular ), local(Lato-Regular), url("lato-latin-400.77db3602.woff2") format("woff2"), url("lato-latin-400.40fe5d42.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Lato Regular italic), local(Lato-Regularitalic), url("lato-latin-400italic.6edbc86c.woff2") format("woff2"), url("lato-latin-400italic.773d6047.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Lato Bold ), local(Lato-Bold), url("lato-latin-700.319eebe3.woff2") format("woff2"), url("lato-latin-700.4e061628.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Lato Bold italic), local(Lato-Bolditalic), url("lato-latin-700italic.2890c80a.woff2") format("woff2"), url("lato-latin-700italic.5a8fbcb5.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Lato Black ), local(Lato-Black), url("lato-latin-900.45ca0f73.woff2") format("woff2"), url("lato-latin-900.28461e40.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Lato Black italic), local(Lato-Blackitalic), url("lato-latin-900italic.f640e30d.woff2") format("woff2"), url("lato-latin-900italic.9cf00a4e.woff") format("woff");
}

/*# sourceMappingURL=index.a3a38fdc.css.map */
